@use 'fl-colors' as colors;
@use 'fl-globals' as globals;
@use 'fl-responsive' as responsive;
@use 'fl-typography' as typography;

$outline-border-width: .125rem;

%button-base {
  align-items: center;
  border: 0;
  border-radius: globals.$border-radius;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: typography.$body-font;
  font-size: .875rem;
  font-weight: 500;
  gap: .25rem;
  height: 2.875rem;
  justify-content: center;
  outline: none;
  text-align: center;
  text-decoration: none;

  svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }
}

%button-filled {
  @extend %button-base;

  background: var(--button-filled-color, colors.$gradient-blueberry-flamingo);
  border: none;
  color: colors.$white;
  padding: 0 1.25rem;

  svg {
    path {
      fill: colors.$white;
    }
  }

  &:visited {
    color: colors.$white;
  }

  &:focus,
  &:hover {
    background: var(--button-filled-color-hover, colors.$gradient-blueberry-plum);
  }

  &:active,
  &.selected {
    background: var(--button-filled-color-active, colors.$gradient-blueberry-shade-40-blueberry);
  }


  &:disabled,
  &.disabled {
    background: var(--button-filled-color-hover, colors.$gradient-blueberry-plum);
    color: colors.$white;
    cursor: default;
    opacity: .4;
  }
}

%button-filled-red {
  @extend %button-filled;

  background: colors.$grapefruit;

  &:focus,
  &:hover {
    background: colors.$grapefruit-shade-40;
  }

  &:active,
  &.selected {
    background: colors.$grapefruit-shade-40;
  }

  &:disabled,
  &.disabled {
    background: colors.$grapefruit;
    color: colors.$white;
    cursor: default;
    opacity: .4;
  }
}

%button-filled-small {
  @extend %button-filled;

  height: 2.25rem;

  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

@mixin button-filled {
  align-items: center;
  background: colors.$gradient-blueberry-flamingo;
  border-radius: globals.$border-radius;
  border: 0;
  box-sizing: border-box;
  color: colors.$white;
  cursor: pointer;
  display: inline-flex;
  font-family: typography.$body-font;
  font-size: .875rem;
  font-weight: 500;
  height: 2.875rem;
  justify-content: center;
  outline: none;
  text-align: center;
  text-decoration: none;

  path {
    fill: colors.$white;
  }

  svg {
    display: block;
    height: 1.5rem;
    margin-right: .25rem;
    width: 1.5rem;
  }

  &:visited {
    color: colors.$white;
  }

  &:focus,
  &:hover {
    background: colors.$gradient-blueberry-plum;
  }

  &:active,
  &.selected {
    background: colors.$gradient-blueberry-shade-40-blueberry;
  }

  &:disabled,
  &.disabled {
    background: colors.$gradient-blueberry-plum;
    color: colors.$white;
    cursor: default;
    opacity: .4;
  }
}

%button-outline {
  @extend %button-base;
  // This is only necessary to correct issue with malformed inline svg
  // Specifically, "copy.svg"
  --svg-fill-color: var(--button-filled-color, #{colors.$blueberry});

  background: transparent;
  border: $outline-border-width solid var(--button-outline-color-border, colors.$blueberry-tint-60);
  color: var(--button-outline-color, colors.$blueberry);
  fill: var(--button-outline-color, colors.$blueberry);
  padding: 0 .6875rem;

  &:focus,
  &:hover,
  &:active,
  &.selected {
    --svg-fill-color: var(--button-filled-color-active, #{colors.$plum});

    border-color: var(--button-outline-color-active, colors.$plum);
    color: var(--button-outline-color-active, colors.$plum);
    fill: var(--button-outline-color-active, colors.$plum);

    &:disabled {
      border-color: var(--button-outline-color-border, colors.$blueberry-tint-60);
      cursor: default;
    }
  }

  &:disabled {
    color: colors.$grey;
    cursor: default;
    fill: colors.$grey;
  }
}

%button-outline-small {
  @extend %button-outline;

  height: 2.25rem;

  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

%button-transparent {
  @extend %button-base;

  background: transparent;
  border: 0;
  color: var(--default-css-color-value, colors.$blueberry);
  padding: .6875rem;

  svg {
    fill: var(--default-css-color-value, colors.$blueberry);;

    path {
      fill: var(--default-css-color-value, colors.$blueberry);;
    }
  }

  &:focus,
  &:hover {
    background: colors.$black-transparent-10;
  }

  &:active,
  &.selected {
    background: var(--button-transparent-active-background, colors.$button-transparent-active-background);
  }
}

%button-transparent-small {
  @extend %button-transparent;

  height: 2.25rem;

  svg {
    height: 1.125rem;
    width: 1.125rem;
  }
}

%button-link {
  @include typography.font-default;
  background: none;
  border: 0;
  color: var(--default-css-color-value, colors.$blueberry);
  cursor: pointer;
  font-weight: 500;
  padding: 0;
  text-align: left;
  text-decoration: none;

  &:hover {
    color: color-mix(in hsl, var(--default-css-color-value, colors.$blueberry), colors.$black 40%);
  }

  &:active,
  &.selected {
    color: color-mix(in hsl, var(--default-css-color-value, colors.$blueberry), colors.$black 80%);
  }
}

%button-mat-overrides {
  border-radius: globals.$border-radius;
  box-shadow: none;
  display: inline-flex;
  line-height: 1.15;
  padding: 0 1rem;
  width: inherit;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

%button-ellipsis {
  @extend %button-base;

  align-items: center;
  background: transparent;
  border: 0;
  display: inline-flex;
  height: 1.5rem;
  justify-content: center;
  padding: 0;
  width: 1.5rem;

  svg {
    fill: colors.$grey;
    margin-right: 0;
  }

  &:focus,
  &:hover {
    background: colors.$fig-tint-95;
  }

  &:active,
  &.selected {
    background: colors.$button-transparent-active-background;
  }
}

%circle-icon-button {
  background: none;
  border: .125rem solid colors.$fig-tint-80;
  border-radius: 2.125rem;
  box-sizing: content-box;
  fill: colors.$blueberry;
  outline: none;
  padding: .5625rem;

  > svg {
    display: block;
    height: 1.5rem;
    width: 1.5rem;
  }

  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: colors.$blueberry;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    opacity: .5;

    path {
      fill: colors.$fig-tint-80;
    }
  }
}

%header-circle-icon-button {
  @extend %circle-icon-button;

  @include responsive.max-resolution(mobile) {
    align-self: center;
    border-color: transparent;
    fill: colors.$white;
    margin: 0 .875rem 0 1.5rem;
    padding: .375rem;

    &:focus,
    &:hover {
      &:not(:disabled) {
        background-color: colors.$fig-tint-95;
        border-color: transparent;
      }
    }
  }

  align-self: flex-start;
  flex-shrink: 0;
  margin-left: 1.5rem;
}
