@use '@angular/material' as mat;
@use 'fl-buttons';
@use 'fl-colors' as colors;
@use 'fl-globals' as globals;
@use 'fl-material-palettes' as palettes;
@use 'fl-modals';
@use 'fl-responsive' as responsive;
@use 'fl-typography' as typography;
// Application theme (global)
@include mat.core();

// Theme
$candy-app-primary: mat.define-palette(palettes.$fl-md-blueberry);
$candy-app-accent: mat.define-palette(palettes.$fl-md-blueberry);
$candy-app-warn: mat.define-palette(palettes.$fl-md-grapefruit);
$candy-app-theme: mat.define-light-theme((
  color: (
    accent: $candy-app-accent,
    primary: $candy-app-primary,
    warn: $candy-app-warn
  ),
  density: -3,
  typography: mat.define-typography-config()
));

@include mat.core-theme($candy-app-theme);
@include mat.button-theme($candy-app-theme);
@include mat.card-theme($candy-app-theme);
@include mat.checkbox-theme($candy-app-theme);
@include mat.chips-theme($candy-app-theme);
@include mat.datepicker-theme($candy-app-theme);
@include mat.select-theme($candy-app-theme);
@include mat.list-theme($candy-app-theme);
@include mat.radio-theme($candy-app-theme);
@include mat.slide-toggle-theme($candy-app-theme);
@include mat.snack-bar-theme($candy-app-theme);
@include mat.table-theme($candy-app-theme);
@include mat.tabs-theme($candy-app-theme);
@include mat.tooltip-theme($candy-app-theme);

// Global MD3 overrides should be applied here
// In general our preferred way to style material components should be to apply variables
// so that we aren't fighting the system, specific containers can be used to apply variables
// to only specific element types if needed, but we should prefer to apply variables as
// broad as is applicable so that md components across the system reflect more consistently
:root {
  @include responsive.high-density-display {
    --mdc-outlined-text-field-outline-width: .0975rem;
    --mdc-outlined-text-field-focus-outline-width: .0975rem;
  }

  --default-css-color: var(--default-css-color-value, #{colors.$blueberry});

  --mat-autocomplete-background-color: #{colors.$white};
  --mat-datepicker-calendar-date-selected-state-background-color: var(--default-css-color);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(in oklab, var(--default-css-color), transparent 60%);
  --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in oklab, var(--default-css-color), transparent 70%);
  --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in oklab, var(--default-css-color), transparent 70%);
  --mat-datepicker-toggle-active-state-icon-color: var(--default-css-color);
  --mat-datepicker-calendar-date-in-range-state-background-color: color-mix(in oklab, var(--default-css-color), transparent 80%);
  // Must be defined to prevent global form issues with input heights.
  // Correct / ideal height is as of yet unknown
  --mat-form-field-container-height: 1rem;
  --mat-option-label-text-size: .875rem;
  --mat-option-selected-state-label-text-color: var(--default-css-color);
  --mat-select-trigger-text-size: .875rem;
  --mdc-checkbox-state-layer-size: 2rem;
  --mdc-chip-container-shape-radius: #{globals.$border-radius};
  --mdc-circular-progress-active-indicator-color: var(--default-css-color);
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-icon-button-state-layer-size: 1.25rem;
  --mdc-linear-progress-active-indicator-color: var(--default-css-color);
  --mdc-linear-progress-active-indicator-height: .375rem;
  --mdc-linear-progress-track-height: .375rem;
  --mdc-outlined-text-field-caret-color: var(--default-css-color);
  --mdc-outlined-text-field-container-shape: #{globals.$border-radius};
  --mdc-outlined-text-field-disabled-input-text-color: #{colors.$fig-shade-40-transparent-40};
  --mdc-outlined-text-field-disabled-label-text-color: #{colors.$fig-shade-40-transparent-40};
  --mdc-outlined-text-field-error-caret-color: #{colors.$grapefruit};
  --mdc-outlined-text-field-input-text-color: #{colors.$fig-shade-40};
  --mdc-outlined-text-field-label-text-color: #{colors.$fig-shade-40-transparent-70};
  --mdc-outlined-text-field-outline-color: #{colors.$black-transparent-20};
  --mdc-outlined-text-field-outline-width: .125rem;
  --mdc-outlined-text-field-focus-outline-width: .125rem;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-radio-state-layer-size: 1.5rem;
  --mdc-tab-indicator-active-indicator-height: .1875rem;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-theme-on-surface: #{colors.$fig-shade-40};
  --mdc-theme-surface: #{colors.$white};
  --mdc-theme-text-primary-on-background: #{colors.$fig-shade-40};
  --mdc-typography-body1-font-size: .875rem;
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-button-letter-spacing: normal;

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-disabled-selected-icon-opacity: .85;
    --mdc-radio-disabled-selected-icon-color: var(--default-css-color);
  }
}

// Duplicate selectors to game specificity, these are necessary for some misplaced variables
// On ng-materials end and can't just be specified at the root
.mat-primary.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--default-css-color);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--default-css-color);
}

html .mat-mdc-slide-toggle.mat-accent.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: color-mix(in hsl, var(--default-css-color), #000 25%);
  --mdc-switch-selected-handle-color: color-mix(in hsl, var(--default-css-color), #000 25%);
  --mdc-switch-selected-hover-state-layer-color: color-mix(in hsl, var(--default-css-color), #000 25%);
  --mdc-switch-selected-pressed-state-layer-color: color-mix(in hsl, var(--default-css-color), #000 25%);
  --mdc-switch-selected-focus-track-color: color-mix(in hsl, var(--default-css-color), #fff 25%);
  --mdc-switch-selected-hover-track-color: color-mix(in hsl, var(--default-css-color), #fff 25%);
  --mdc-switch-selected-pressed-track-color: color-mix(in hsl, var(--default-css-color), #fff 25%);
  --mdc-switch-selected-track-color: color-mix(in hsl, var(--default-css-color), #fff 25%);
}

// MD3 Chips
// Double selector necessary to overwrite mat's rediculous selector specificity
.mat-mdc-standard-chip.mat-mdc-standard-chip {
  // There are actually variable configurations available for some of these styles
  // but it seems that as of angular material v15 some of these are misconfigured
  // and local element styles will always supercede them, so for the time being we have
  // to provide explicit attributes
  // --mdc-typography-body2-font-weight: 700;
  // --mdc-typography-body2-text-transform: uppercase;
  .mdc-evolution-chip__text-label {
    font-weight: 700;
    text-transform: uppercase;
  }

  .mdc-evolution-chip__action--primary {
    padding-left: 0;
    padding-right: 0;
  }

  // Used to style the padding on the chips caused by a placeholder for
  // leading and trailing elements in chips, may need to be moved to their respective
  // places depending on final font sizes, see:
  // fl-tags-menu
  &.mdc-evolution-chip--with-primary-graphic {
    &.mdc-evolution-chip--with-trailing-action {
      .mdc-evolution-chip__graphic,
      .mdc-evolution-chip__action--trailing {
        padding-left: .1875rem;
        padding-right: .1875rem;
      }
    }
  }
}

// MD3 Empty Hints
// For some reason, Material now produces hint elements even when there's no data
// while likely semantically correct code wise, we'd been relying on the behavior
// where it omits empty elements in the past, so this is required to prevent
// odd spacing
.mat-mdc-form-field-hint:empty {
  display: none;
}

// MD3 Form Fields (Outline)
// Our typical input field is wrapped in `<mat-form-field appearance="outline">`
// This may not always be the case and we should be on the lookout for other implementations
// so that we can unify
.mat-form-field-appearance-outline {
  background-color: colors.$white;
  // This is where colors for borders are now derived, see below for various states
  color: colors.$black-transparent-20;

  &.mat-form-field-disabled {
    color: colors.$black-transparent-10;
  }

  // Support outlined fields with icons (see login.html)
  .mat-mdc-form-field-infix:not(:has(fl-froala-editor)) {
    align-items: center;
    display: flex;
    gap: .25rem;
    width: auto;

    > :nth-child(2) {
      margin-right: .25ch;
    }
  }

  // Forces inputs to the height we want to match button heights in the app
  .mat-mdc-text-field-wrapper:not(:has(.froala-editor, .mat-mdc-chip-grid, textarea)) {
    height: 2.875rem;
  }

  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-flex {
    align-items: center;
  }

  &.mat-mdc-form-field-has-icon-suffix {
    padding: 0;

    .mat-mdc-text-field-wrapper {
      padding-right: .75rem;
    }
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-width: var(--mdc-outlined-text-field-outline-width);
  }

  // MD3 Text fields and textareas
  .mdc-text-field--outlined {
    padding: .625rem .75rem;

    &:has(fl-froala-editor) {
      padding: 0;
    }

    .mdc-floating-label {
      top: 0;
    }

    // Double outline specifier to override specificity in ng theme
    .mdc-notched-outline__leading.mdc-notched-outline__leading {
      width: .625rem;
    }

    // For input[type=*]
    .mdc-notched-outline--upgraded {
      .mdc-floating-label--float-above {
        transform: translateY(-.5rem) scale(0.75);
      }
    }
  }

  // Double specifier required to supercede Mat settings (because they do the same 😂)
  .mat-mdc-input-element.mat-mdc-input-element {
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.3;
    min-height: 1.5rem;
    text-transform: none;
  }

  &:not(.mdc-text-field-disabled) {
    .mat-mdc-input-element.mdc-text-field__input {
      &::placeholder {
        color: colors.$black-tint-70;
      }
    }
  }

  .mdc-text-field--no-label.mdc-text-field--textarea .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper textarea.mat-mdc-form-field-input-control {
    min-height: 6rem;
  }

  // MD3 mat-hint changes
  .mat-mdc-form-field-hint {
    color: colors.$black-tint-50;
    font-size: .75rem;
    margin-top: .5rem;

    &.mat-mdc-form-field-bottom-align {
      &::before {
        display: none;
      }
    }
  }

  .mat-mdc-form-field-hint-spacer {
    flex: 1 0 0;
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
  }

  // Selected used by MDC to target inputs, needed to overwrite specificity
  // to correct [hidden] attribute
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
    &[hidden] {
      display: none;
    }
  }

  // MD3 Datepicker
  .mat-datepicker-toggle {
    color: inherit;

    .mat-mdc-icon-button {
      transform: translateY(-.125rem);
      --mdc-icon-button-state-layer-size: 1.25rem;
      padding: 0;
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;
      fill: currentColor;
    }
  }

  // MD3 Select fields
  .mat-mdc-select {
    --mat-select-trigger-text-tracking: normal;

    box-sizing: border-box;
    display: flex;
    min-height: 2rem;
    text-transform: none;

    .mat-mdc-select-trigger {
      gap: .5rem;
    }

    // TODO: We should create a $chevron-down-red for error states
    .mat-mdc-select-arrow {
      background-image: url(globals.$chevron-down-grey);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 120%;
      border: 0;
      height: 1.25rem;
      margin: 0;
      width: 1.25rem;

      svg {
        display: none;
      }
    }
  }

  &.mat-focused {
    .mat-mdc-select {
      .mat-mdc-select-arrow {
        background-image: url(globals.$chevron-down-dark-grey)
      }
    }
  }

  &.mat-form-field-invalid {
    .mat-mdc-select {
      .mat-mdc-select-arrow {
        background-image: url(globals.$chevron-down-red);
      }
    }
  }

  // These are workarounds to apply border colors for various states – it seems there's
  // something wrong with material's theme declarations and borders aren't applied _at all_
  // and instead inherit from the color of the parent
  &.mat-focused {
    color: var(--input-border-color-focused, colors.$blueberry);
  }

  &.mat-form-field-invalid {
    color: colors.$grapefruit;
  }

  &:has([readonly]) {
    opacity: .75;
  }
}

// MD3 Checkboxes
.mat-mdc-checkbox {
  .mdc-form-field {
    align-items: flex-start;

    // This margin is necessary in forms to align top of text with center of checkboxes,
    // we don't want to align-items: center above because output can be very long and this
    // solves for both cases
    // – may need to adjust if there's a font-size descreprancy between views
    > label {
      margin-top: .3125rem;
    }
  }
}

// MD3 Radio Groups & Buttons
.mat-mdc-radio-button {
  .mdc-form-field {
    align-items: flex-start;
  }

  // Added to set top padding on radio alignment within forms, may need to be
  // moved into section if this impacts other views
  .mdc-label {
    margin-top: .125rem;
  }
}

// MD3 Form Fields (Fill)
// This is for global changes pertaining to the standard or "fill" appearance of form fields
.mat-form-field-appearance-fill {
  .mat-mdc-form-field-infix {
    width: auto;
  }
}

// MD3 Tables
.mat-mdc-table {
  fl-app-layout &,
  fl-blank-layout &,
  fl-form-layout &,
  fl-settings-layout & {
    // Most backgrounds I've encountered across the app feature the same light-grey background
    // the only exceptions I've found are within our custom cards, so those can be styled white
    // by their respective components
    background: colors.$fig-tint-95;
  }
}

// MD3 Tabs
.mat-mdc-tab-labels {
  gap: 1.5rem;
}

.mat-mdc-tab-header {
  // selector is only here to overwrite specificity, doesn't actually pertain to this
  // component
  fl-documents-app & {
    border-bottom: .0625rem solid colors.$fig-tint-80;
    overflow: visible;
  }

  .mdc-tab {
    --mat-tab-header-label-text-tracking: normal;

    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .mat-mdc-tab-link-container {
    overflow: visible;
  }

  .mdc-tab-indicator {
    transform: translateY(.0625rem);
  }

  .mat-mdc-tab-links {
    gap: 1.5rem;
  }

  .mat-mdc-tab-link {
    --mat-tab-header-label-text-tracking: normal;

    .mdc-tab__text-label {
      color: colors.$grey;
    }

    .mdc-tab__ripple {
      &::before {
        display: none;
      }
    }

    button {
      background: transparent;
      border: 0;
      border-radius: .25rem;
      cursor: pointer;
      display: flex;
      margin-left: .25rem;
      padding: 0;

      &:hover {
        background: colors.$fig-tint-80;
      }
    }

    svg {
      fill: colors.$grey;
      width: 1.25rem;
    }
  }
}

// MD3 menu dropdowns ( used for selects and other elements )
.mat-mdc-select-panel {
  box-shadow: 0 .5rem 1.25rem .125rem colors.$black-transparent-10;
  min-width: fit-content;

  hr {
    background: colors.$grey;
    border: 0;
    display: block;
    height: .0625rem;
    margin: 0 1rem;
  }

  .mat-mdc-option {
    --mat-option-label-text-tracking: normal;

    line-height: 1.15;
    min-width: max-content;

    small {
      color: colors.$grey;
      display: block;
      font-size: .6875rem;
    }
  }
}

// MD3 Tooltips
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #{colors.$fig-shade-80};
  --mdc-plain-tooltip-supporting-text-size: .875rem;
  --mdc-plain-tooltip-supporting-text-tracking: normal;

  .mdc-tooltip__surface {
    max-width: 35ch;
    overflow: visible;
    padding: .875rem;

    &::before {
      border: .5rem solid transparent;
      border-radius: 0;
      height: 0;
      left: 50%;
      transform: translate(-50%);
      width: 0;
    }
  }

  .mat-mdc-tooltip-panel-below & {
    .mdc-tooltip__surface {
      &::before {
        border-bottom-color: colors.$fig-shade-80;
        bottom: 100%;
        top: auto;
      }
    }
  }

  .mat-mdc-tooltip-panel-above & {
    .mdc-tooltip__surface {
      &::before {
        border-top-color: colors.$fig-shade-80;
        bottom: auto;
        top: 100%;
      }
    }
  }
}

// MD3 Menus
.mat-mdc-menu-panel {
  --mat-menu-container-color: #{colors.$white};
  --mdc-shape-medium: #{globals.$border-radius};

  box-shadow: 0 .5rem 1.25rem .125rem colors.$black-transparent-10;
  min-height: auto;
  padding: 1rem 0;

  h5 {
    @include typography.font-heading-5;
    padding: 0 2rem;
  }

  hr {
    background: colors.$fig-shade-40;
    border: 0;
    height: .125rem;
    margin: .125rem 1rem;
    opacity: .05;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item {
    font-size: .875rem;
    min-height: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &:hover:not([disabled]) {
      background: colors.$fig-tint-95;
    }
  }

  .mat-mdc-menu-item-text {
    align-items: center;
    display: flex;
    gap: .5rem;
  }
}

// MD3 Slide Toggle (mat-slide-toggle)
.mat-mdc-slide-toggle {
  .mdc-form-field {
    --mdc-typography-body2-font-weight: 700;
    align-items: flex-start;

    .mdc-switch {
      margin-top: .25rem;
    }

    > label {
      padding-left: .5rem;

      p {
        margin: 0;
      }
    }
  }
}

// MD3 Snackbar
mat-snack-bar-container {
  color: colors.$white;

  &.snack-error {
    .mdc-snackbar__surface {
      background: colors.$grapefruit-shade-10;
    }
  }

  &.snack-check {
    .mat-mdc-simple-snack-bar {
      background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTYuOSwxMy41Yy0wLjQtMC40LTAuNC0xLDAtMS40YzAuNC0wLjQsMS0wLjQsMS40LDBsMi44LDIuOGw2LjQtNi40YzAuNC0wLjQsMS0wLjQsMS40LDAgYzAuNCwwLjQsMC40LDEsMCwxLjRMMTEuOSwxN2wwLDBjLTAuNCwwLjQtMC45LDAuNC0xLjMsMC4xTDEwLjUsMTdsMCwwTDYuOSwxMy41eiIgZmlsbD0iI0ZGRkZGRiIvPjwvc3ZnPgo=');
      background-position: left -.1875rem;
      background-repeat: no-repeat;
      background-size: 1.5rem;
      padding-left: 1.625rem;
    }
  }

  .mdc-button__label {
    text-transform: uppercase;
  }

  .mat-mdc-button {
    &.mat-mdc-snack-bar-action:not(:disabled) {
      --mat-mdc-snack-bar-button-color: #{colors.$blueberry-tint-20};
    }
  }

  .mdc-snackbar__surface {
    background: colors.$fig-shade-80;
    border-radius: globals.$border-radius;
    box-shadow: 0 .25rem .5rem 0 colors.$black-transparent-12;
  }
}

// MD3 / ng-mat v17 fix
// As of v17, angular material introduces a `display: contents` style to this class
// which is applied to all mdc dialogs, and we explicitly depend on these elements being
// displayed as block ( double selector to hit specificity )
.mat-mdc-dialog-component-host.mat-mdc-dialog-component-host {
  display: block;
}

///////////////////////////////////////////////////////////////////////////////////////////////
///                                                                                         ///
/// EVERYTHING BELOW THIS POINT HAS NOT BEEN VALIDATED FOR MD3                              ///
/// THEORETICALLY, IT IS SAFE BUT WE'D HAVE TO FIND CONTEXT IN THE APP                      ///
/// TO CONFIRM, IF WE COME ACROSS THESE USE CASES WE SHOULD MOVE THEM ABOVE THIS MARKER     ///
///                                                                                         ///
///////////////////////////////////////////////////////////////////////////////////////////////

.mat-mdc-unelevated-button {
  &.mat-primary {
    color: colors.$white;
  }
}

// Overrides black color used for type and box-shadow
.mat-calendar {
  .mat-calendar-body-selected {
    box-shadow: inset 0 0 0 1px colors.$white;
    color: colors.$white;
  }
}

// Override mat-modals via cdk-overlay-container
.cdk-overlay-container {
  @extend %mat-modals;
}

a {
  color: var(--default-css-color-value, colors.$blueberry);

  &.mat-mdc-button {
    @include typography.font-default;
    font-weight: 700;

    &.mat-primary {
      @extend %button-mat-overrides;
      color: var(--default-css-color-value, colors.$blueberry);
      line-height: 1.15;
    }
  }
}

// Ink Bars
mat-ink-bar.mat-ink-bar {
  @media (prefers-reduced-motion) {
    display: none;
  }
}

.mat-mdc-tab-link.active {
  &::after {
    @media (prefers-reduced-motion) {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: .125rem;
      background: colors.$blueberry;
      right: 0;
      bottom: 0;
    }
  }
}
