// Colors used through-out the app, try to be descriptive about their
// application or use-case so it's easier to re-use in multiple parts
// of the application (Exception being branding colors, which we can
// refer to in the abstract sheet 20.11

$white: #fff;

$light-grey: #fafafa;

$grey: #8c8ba7;
$grey-shade-50: mix(black, $grey, 50%);
$grey-tint-20: mix(white, $grey, 20%);

$black: #000;
$black-tint-70: mix(white, $black, 70%);
$black-tint-50: mix(white, $black, 50%);
$black-tint-25: mix(white, $black, 25%);

$fig: #736eaa;
$fig-shade-80: mix(black, $fig, 80%);
$fig-shade-80-transparent-20: rgba($fig-shade-80, .2);
$fig-shade-40: mix(black, $fig, 40%);
$fig-shade-40-transparent-40: rgba($fig-shade-40, .4);
$fig-shade-40-transparent-70: rgba($fig-shade-40, .7);
$fig-shade-10: mix(black, $fig, 10%);
$fig-tint-30: mix(white, $fig, 30%);
$fig-tint-70: mix(white, $fig, 70%);
$fig-tint-80: mix(white, $fig, 80%);
$fig-tint-90: mix(white, $fig, 90%);
$fig-tint-95: mix(white, $fig, 95%);

$blueberry: #5a4aff;
$blueberry-shade-80: mix(black, $blueberry, 80%);
$blueberry-shade-60: mix(black, $blueberry, 60%);
$blueberry-shade-40: mix(black, $blueberry, 40%);
$blueberry-shade-20: mix(black, $blueberry, 20%);
$blueberry-tint-20: mix(white, $blueberry, 20%);
$blueberry-tint-40: mix(white, $blueberry, 40%);
$blueberry-tint-50: mix(white, $blueberry, 50%);
$blueberry-tint-60: mix(white, $blueberry, 60%);
$blueberry-tint-70: mix(white, $blueberry, 70%);
$blueberry-tint-80: mix(white, $blueberry, 80%);
$blueberry-tint-85: mix(white, $blueberry, 85%);
$blueberry-tint-90: mix(white, $blueberry, 90%);
$blueberry-tint-95: mix(white, $blueberry, 95%);

$plum: #892fff;
$plum-shade-20: mix(black, $plum, 20%);

$huckleberry: #53a5f8;
$huckleberry-shade-40: mix(black, $huckleberry, 40%);
$huckleberry-tint-40: mix(white, $huckleberry, 40%);
$huckleberry-tint-60: mix(white, $huckleberry, 60%);
$huckleberry-tint-80: mix(white, $huckleberry, 80%);
$huckleberry-tint-90: mix(white, $huckleberry, 90%);

$mango: #ffc224;
$mango-shade-60: mix(black, $mango, 60%);
$mango-shade-40: mix(black, $mango, 40%);
$mango-shade-20: mix(black, $mango, 20%);
$mango-tint-50: mix(white, $mango, 50%);
$mango-tint-60: mix(white, $mango, 60%);
$mango-tint-80: mix(white, $mango, 80%);
$mango-tint-90: mix(white, $mango, 90%);

$honeydew: #037c42;
$honeydew-shade-20: mix(black, $honeydew, 20%);
$honeydew-tint-90: mix(white, $honeydew, 90%);
$honeydew-tint-80: mix(white, $honeydew, 80%);

$cucumber: #8df8c5;
$cucumber-tint-60: mix(white, $cucumber, 60%);

$grapefruit: #cc0000;
$grapefruit-shade-80: mix(black, $grapefruit, 80%);
$grapefruit-shade-60: mix(black, $grapefruit, 60%);
$grapefruit-shade-40: mix(black, $grapefruit, 40%);
$grapefruit-shade-20: mix(black, $grapefruit, 20%);
$grapefruit-shade-10: mix(black, $grapefruit, 10%);
$grapefruit-tint-20: mix(white, $grapefruit, 20%);
$grapefruit-tint-40: mix(white, $grapefruit, 40%);
$grapefruit-tint-60: mix(white, $grapefruit, 60%);
$grapefruit-tint-80: mix(white, $grapefruit, 80%);
$grapefruit-tint-90: mix(white, $grapefruit, 90%);
$grapefruit-tint-95: mix(white, $grapefruit, 95%);

$flamingo: #ff00ff;

$gradient-blueberry-flamingo: linear-gradient(32.62deg, $blueberry -10.98%, $flamingo 134.21%);
$gradient-blueberry-plum: linear-gradient(45deg, $blueberry 0%, $plum 100%);
$gradient-blueberry-shade-40-blueberry: linear-gradient(259.32deg, $blueberry-shade-40 0%, $blueberry 100%);
$gradient-grey: linear-gradient(225deg, $grey 0%, $fig-tint-80 100%);
$gradient-mango-tint-80-mango: linear-gradient(225deg, $mango 0%, $mango-tint-80 100%);
$gradient-plum-shade-20-blueberry-shade-20: linear-gradient(45deg, $plum-shade-20 0%, $blueberry-shade-20 100%);
$gradient-grey-fig-tint-80: linear-gradient(45deg, $grey 0%, $fig-tint-80 100%);
$gradient-fig-shade-40-fig-shade-10: linear-gradient(45deg, $fig-shade-40 0%, $fig-shade-10 100%);

// Transparencies for shadows

$black-transparent-02: rgba($black, .02);
$black-transparent-04: rgba($black, .04);
$black-transparent-08: rgba($black, .08);
$black-transparent-10: rgba($black, .1);
$black-transparent-12: rgba($black, .12);
$black-transparent-16: rgba($black, .16);
$black-transparent-20: rgba($black, .2);
$black-transparent-50: rgba($black, .5);

$white-transparent-50: rgba($white, .5);

// General variables

$account-switcher-divider: rgba($white, .1);
$account-switcher-divider-provider: rgba($grey, .1);
$authentication-text-color: $fig-shade-40;
$authentication-disabled-color: $blueberry-tint-60;
$authentication-hint-color: $grey;
$tooltip-text-color: $fig-tint-95;
$active-table-row: rgba($blueberry, .02);
$border-lighter: rgba($fig-shade-40, .1);
$button-transparent-active-background: rgba($blueberry, .2);
$download-btn-gradient: linear-gradient(45deg, $plum, $blueberry);
$form-layout-lines: rgba($fig-shade-40, .05);
$form-layout-title: $grey;
$layout-gradient-end: mix($black, $blueberry, 22%);
$layout-gradient-start: mix($black, $plum, 22%);
$layout-gradient-end-provider: $fig-tint-80;
$layout-gradient-start-provider: $grey;
$mfa-placeholder-text-color: $fig-tint-80;
$nav-white: rgba($white, .7);
$settings-header-border-color: $fig-tint-80;
$settings-section-border: $fig-tint-80;
$settings-section-strong: $fig-shade-80;
$table-header-border: $fig-tint-80;
$table-list-separator: rgba($fig-shade-40, .1);
$table-seperator-border: $fig-tint-80;
$tag-default-bg-color: $huckleberry-tint-80;
$tag-focus-bg-color: $huckleberry-tint-60;
